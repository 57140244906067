import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Portal } from '@mui/material';

const CustomSnackbar = ({ open, message, severity, onClose, duration = 6000 }) => {
  return (
    <Portal>
      <Snackbar 
        open={open} 
        autoHideDuration={duration} 
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ mt: "3.2rem", zIndex: 1400 }}
      >
        <Alert 
          onClose={onClose} 
          severity={severity} 
          sx={{ 
            width: '100%',
            zIndex: 1400,
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default CustomSnackbar;
