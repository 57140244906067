import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from '../services/auth.js';
import "../Amenities.css"

import BackgroundImage from '../components/Cityline.js';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PaymentsIcon from '@mui/icons-material/Payments';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import Drawer from '@mui/material/Drawer';

import axios from 'axios';
import AppConfig from '../services/app-config.js';

export default function Home({setNavbarState, navbarState, window}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [loading, setLoading] = React.useState(true);
  const [amenity, setAmenity] = React.useState({});
  const [amenityReservations,setAmenityReservations] = React.useState({});
  const [reservation,setReservation] = React.useState({});
  const [drawerShow, setDrawerShow] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [date,setDate] = React.useState(dayjs());
  const [timeRanges, setTimeRanges] = React.useState({});
  const [deleteDialog,setDeleteDialog] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [refresh,setRefresh] = React.useState(false);

  const reservationId = new URLSearchParams(location.search).get('id');
  let snackbarmsg = new URLSearchParams(location.search).get('msg');
  let snackbarlvl = new URLSearchParams(location.search).get('lvl',"success");
  const amenityId = reservationId.split('-')[0];
  const apiUrl = process.env.REACT_APP_MGO_API_URL;

  const weekDays = ["Lunes","Martes","Miercoles","Jueves","Viernes","Sabado","Domingo"]
  const monthsSpa = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octobre","Noviembre","Diciembre"]

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const closeReservationDialog = () => {
    setDeleteDialog(false);
  }

  const triggerRefresh = () => {
    setRefresh(!refresh);
  }

  const deleteReservation = () => {
    setLoading(true);
    setShowBackdrop(true);
    setDeleteDialog(false);
    let resDate = dayjs.unix(reservationId.split('-')[1]).format('DDMMYY');
    axios.post(`${apiUrl}/reservation/delete`, {dia: resDate, reservacion: reservationId})
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setRedirect({ trigger: true, route: `/amenidades?msg=Reservacion eliminada exitosamente&lvl=success` });
        } else if (response.status === 422) {
          setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
        }
        setLoading(false);
        setShowBackdrop(false);
      })
      .catch(error => {
        if(error.response && error.response.data && error.response.data.code === 422) {
          setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
        }
        else  {
        console.error('Error deleting reservation:', error);
        setSnackbar({ open: true, message: "Hubo un error al intentar eliminar la reservacion porfavor intenta de nuevo mas tarde.", severity: 'error' })};
        setLoading(false);
        setShowBackdrop(false);
      });
  }

  const deleteReservationPre = () => {
    setDeleteDialog(true);
  }

  const handleDrawerToggle = () => {
    setDrawerShow(!drawerShow);
  }

  function convertEpochsToTimeString(epoch1, epoch2) {
    const date1 = new Date(epoch1 * 1000);
    const date2 = new Date(epoch2 * 1000);

    const day = date1.getDate();
    const month = date1.toLocaleString('default', { month: 'short' });
    const time1 = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const time2 = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return `${day}/${month} ${time1} - ${time2}`;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const ceil = (option, dayjsClass) => {
    dayjsClass.prototype.ceil = function (unit, amount) {
        return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
    };
  };
  dayjs.extend(ceil);

  const fetchReservations = (date) => {
    const formated_date = date.format('DDMMYY');
    axios.get(`${apiUrl}/amenity/reservations/${formated_date}/${amenityId}`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          const dataArray = Object.entries(response.data.data).map(([id, value]) => ({ id, ...value }));
          console.log(dataArray);
          if (response.data.data.hasOwnProperty(reservationId)) {
            setReservation(response.data.data[reservationId]);
          } else {
            setRedirect({ trigger: true, route: '/amenidades' });
            return
          }
          if (!AuthService.userRoleIncludes(["admin","receptor"]) && response.data.data[reservationId].usuario !== AuthService.getCurrentUserEmail()) {
            setRedirect({ trigger: true, route: '/amenidades' });
            return
          }
          setAmenityReservations(dataArray);
          if (snackbarmsg !== null) {
            setSnackbar({ open: true, message: snackbarmsg, severity: snackbarlvl });
            setRedirect({ trigger: true, route: `/reservacion?id=${reservationId}` });
            snackbarmsg = null;
          }
          setShowBackdrop(false);
          setLoading(false);  
        }
      })
      .catch(error => {
        console.error('Error fetching reservations:', error);
        setSnackbar({ open: true, message: 'Hubo un error obteniendo las reservaciones de la amenidad', severity: 'error' });
        setShowBackdrop(false);
          setLoading(false);  
      });
  }

  const fetchInfo = (date,id) => {

    const dayOfWeek = date.day() || 7;
    axios.get(`${apiUrl}/amenity/${id}`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          let disponible_hoy = false;
          let inicio = date;
          let inicio_uf = "00:00";
          let fin = date;
          let fin_uf = "23:59";
          let amenityTemp = response.data.data;
          amenityTemp.dia_de_semana = weekDays[dayOfWeek-1]
          amenityTemp.mes = monthsSpa[date.month()];
          amenityTemp.showBlock = false;
          let timeRangesTemp = {"from": {"min":date,"max":date,"value":date}, "to": {"min":date,"max":date,"value": date}}
          for (const horario of response.data.data.horarios) {
            if (horario.dias.includes(dayOfWeek)) {
              disponible_hoy = true;
              inicio_uf = horario.hora_inicio;
              fin_uf = horario.hora_fin;
              inicio = date.set('hour', parseInt(horario.hora_inicio.split(':')[0])).set('minute', parseInt(horario.hora_inicio.split(':')[1])).set('second', 0);
              fin = date.set('hour', parseInt(horario.hora_fin.split(':')[0])).set('minute', parseInt(horario.hora_fin.split(':')[1])).set('second', 0);
              timeRangesTemp = {"from": {"min":inicio,"max":fin.subtract(amenityTemp.reservacion.tiempo_minimo-1,"minutes"),"value": inicio}, "to": {"min":inicio.add(amenityTemp.reservacion.tiempo_minimo - 1, "minutes"),"max":fin,"value":inicio.add(amenityTemp.reservacion.tiempo_minimo*2,"minutes")}};
              break;
            }
          }
          if (disponible_hoy && amenityTemp.bloquear) {
            if (dayjs.unix(amenityTemp.bloquear.fecha_fin).isAfter(inicio)) {
              amenityTemp.showBlock = true;
            }
          }
          setTimeRanges(timeRangesTemp);
          setAmenity({"disponible_hoy": disponible_hoy, "horario_hoy": {"inicio": inicio,"fin": fin, "inicio_uf":inicio_uf, "fin_uf": fin_uf},...response.data.data});
          fetchReservations(date);     
        }
      })
      .catch(error => {
        console.error('Error fetching amenities:', error);
        setSnackbar({ open: true, message: 'Hubo un error obteniendo los detalles de la amenidad', severity: 'error' });
        setLoading(false);
        setShowBackdrop(false);
      });
  }

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const reservationDrawer = (
    <Card
    sx={{ py: 3, px: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto', borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", maxWidth: "550px", width: "100%" }}>
        
      <CardContent>
      </CardContent>
      <CardActions>
        <Button size="medium" color="inherit" variant="outlined" onClick={handleDrawerToggle}>Cancelar</Button>
        <Button size="medium" color="secondary" variant="contained" onClick={() => deleteReservationPre()} disabled={amenity.conflicto} >Reservar</Button>
      </CardActions>
    </Card>
  );
    

  React.useEffect(() => {
    setNavbarState({ ...navbarState, showBack: true, showOptions: false, showSearch: false})
    if (reservationId === null) {
      setRedirect({ trigger: true, route: '/amenidades' });
    }
    let resDate = dayjs.unix(reservationId.split('-')[1]);
    setShowBackdrop(true);
    setLoading(true);
    fetchInfo(resDate,amenityId);
  }, [date,refresh]);

const container = window !== undefined ? () => window().document.body : undefined;
return (
    <>
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
    <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
        {snackbar.message}
      </Alert>
    </Snackbar>
    <Box sx={{ mt: 1 }}>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
        {!loading && (
          <>
         <img src={`${apiUrl}/img/amenities/${amenityId}.jpg`} alt="Amenity Image" style={{ width: '100%', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', paddingLeft: 0}} />
          <Card
            key={reservationId}
            variant="outlined"
            sx={{ mt: -0.6, borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
          >
            <CardContent>
              <Typography variant="h5" component="div" sx={{mt: 1, mb: -0.1}}>
                <b>{amenity.nombre}</b>
              </Typography>
              <Typography variant="h6" color="text.primary">
                {convertEpochsToTimeString(reservation.inicio,reservation.fin)} 
              </Typography>
              {!AuthService.userRoleIncludes(["admin","receptor"]) ? (
                <Box>
                  {reservation.fin > dayjs().unix() ? (
                  <>
                    {reservation.confirmada ? (
                  
                      <Typography variant="h6" color="text.secondary" sx={{mt: 1}}>
                        Confirmada 
                      </Typography>
                    ) : (
                      <Typography variant="h6" color="text.secondary" sx={{mt: 1}}>
                        Pendiente de Confirmar
                      </Typography>
                    )}
                  </>
                  )
                  :
                  (
                      <Typography variant="h6" color="text.secondary" sx={{mt: 1}}>
                        Reservacion Pasada 
                      </Typography>
                  )}
                  {reservation.confirmada ? (
                    <Typography variant="body1" color="text.primary">
                      <>
                        <Typography variant="body1" color="text.primary">
                          <CheckCircleIcon color="success" style={{ fontSize: '1rem', verticalAlign: 'middle' }} sx={{mr : 0.5}}/>
                          Tu reservacion esta confirmada. 
                        </Typography>
                      </>
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="body1" color="text.primary">
                        <CancelIcon color="error" style={{ fontSize: '1rem', verticalAlign: 'middle' }} sx={{mr : 0.5}}/>
                        Recuerda que tu reservacion no es valida hasta que administracion la confirme 
                      </Typography>
                      {amenity.reservacion.monto_pago > 0 && (
                        <>
                          <Typography variant="h6" color="text.secondary" sx={{mt: 1}}>
                            Monto a pagar Q. {amenity.reservacion.monto_pago.toFixed(2)}
                          </Typography>
                          <Typography variant="body1" color="text.primary">
                            <PaymentsIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} sx={{mr : 0.5}}/>
                            Recuerda notificar tu pago a administracion para agilizar la confirmacion de tu reservacion 
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                  {amenity.obs.length > 0 && (
                    <>
                      <Typography variant="h6" component="div" sx={{mt: 1}}>
                        Reglas y Observaciones
                      </Typography>
                      <ul style={{ paddingLeft: "18px"}}>
                        {amenity.obs.map((nota, index) => (
                          <li key={`nota-${index}`}>
                            <Typography variant="body1" color="text.primary">
                              {nota}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </Box>
              )
              :
              (
                <Box>
                {reservation.fin > dayjs().unix() ? (
                <>
                  {reservation.confirmada ? (
                
                    <Typography variant="h6" color="text.secondary" sx={{mt: 1}}>
                      Confirmada 
                    </Typography>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="h6" color="text.secondary" sx={{ mt: 1, flexGrow: 1 }}>
                        Pendiente de Confirmar
                      </Typography>
                      {AuthService.userRoleIncludes(["admin"]) && (
                        <Button color="secondary" variant="outlined" size="medium" onClick={() => setRedirect({ trigger: true, route: '/aprobaciones' })} sx={{ mt: 0.5 }}>
                          Ir a Aprobaciones
                          <ArrowForwardIcon sx={{ml: 0.5}} />
                        </Button>
                      )}
                    </Box>
                  )}
                </>
                )
                :
                (
                    <Typography variant="h6" color="text.secondary" sx={{mt: 1}}>
                      Reservacion Pasada 
                    </Typography>
                )}
                  <Divider sx={{mt: 1, mb: 1}}/>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ mt: 1, ml: 1, flexGrow: 1 }}>
                      <b>{reservation.residente}</b>
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1, mr: 1 }}>
                      {reservation.unidad} 
                    </Typography>
                  </Box>
                </Box>
              )}
              <Timeline position="left" sx={{ maxHeight: '30vh', overflow: 'auto' }}>
              <TimelineItem key="starting-time">
                <TimelineOppositeContent color="text.secondary">{amenity.horario_hoy.inicio_uf}</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="success" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Disponible</TimelineContent>
              </TimelineItem>
              {amenityReservations.map((reservation, index) => (
                <TimelineItem key={reservation.id}>
                  <TimelineOppositeContent color="text.secondary">
                    {AppConfig.formatHour(reservation.inicio)} a {AppConfig.formatHour(reservation.fin)}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color={reservation.usuario === AuthService.getCurrentUserEmail() && reservation.confirmada ? "success" : "warning" } variant="outlined" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {reservation.usuario === AuthService.getCurrentUserEmail() ? 'Esta Reservacion' : 'Reservado'} 
                    <Typography variant="body2" color="text.secondary">
                    {reservation.fin > dayjs().unix() && (<>{reservation.confirmada ? 'Confirmada' : 'Pendiente'}</>)}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
              <TimelineItem key="finishing-time">
                <TimelineOppositeContent color="text.secondary">{amenity.horario_hoy.fin_uf}</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>Cerrado</TimelineContent>
              </TimelineItem>
            </Timeline>
              
            </CardContent>
            <CardActions >
              {reservation.fin > dayjs().unix() && (
              <Button color="primary" variant="outlined" size="medium" onClick={() => deleteReservationPre()} sx={{mt: 2, mb: 2}}>
                <CancelIcon sx={{mr: 0.5}}/>
                Cancelar Reserva
              </Button>
              )}
            </CardActions>
          </Card>
          </>)}
    </Box>
    {!loading && (
    <>
    <Drawer
          container={container}
          anchor="bottom"
          variant="temporary"
          open={drawerShow}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
          }}
        >
          {reservationDrawer}
    </Drawer>
    <Dialog open={deleteDialog} onClose={closeReservationDialog}>
        <DialogTitle>Cancelar Reserva</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteDialog ? <Typography>¿Está seguro que desea cancelar esta reservacion?</Typography> : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeReservationDialog} color="secondary">
            No Cancelar
          </Button>
          <Button onClick={() => deleteReservation()} color="primary">
            Cancelar
          </Button>
        </DialogActions>
    </Dialog>
    </>
    )}
      
    <BackgroundImage />
    </Container>
  </>);
}